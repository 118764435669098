
import React, { useState, memo, useMemo } from 'react';
import { redirect, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, Input } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import Settings from '../../dependencies/custom/settings';


const Login = (props) => {
    const [indexNumber, setIndexNumber] = useState();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const navigate = useNavigate();
    const [btnLoading, setBtnLoading] = useState(false);
    useMemo(() => {

    }, []);

    async function login() {
        setBtnLoading(true);
        await utils.sameOriginLogin(username, password, { index_no: indexNumber }, navigate, '../landing', 'body', `${Settings.backend}/aspirant_login`, ['credentials']);
        setBtnLoading(false);
    }

    return (
        <>
            <div className='container'>
                <div className='row'>
                    {/* 546E7A 4E6A7A 547A6E b7000f*/}
                    <div className='col-sm-12 col-md-6 col-lg-3 mx-auto mb-2 border rounded p-3' style={{ marginTop: '15%', background: '#4E6A7A' }}>
                        <Space className='w-100' direction='vertical'>
                            <div className='d-flex justify-content-center rounded' >
                                <img className='mx-auto mb-3' width={190} height={150} src={`${Settings.backend}/fav.png`} />
                            </div>
                            <Input onPressEnter={e => login()} className='mb-2x rounded' type='text' prefix={<UserOutlined className="site-form-item-icon" />} placeholder='Index Number' value={indexNumber} onChange={e => setIndexNumber(e.target.value)} />
                            <Input.Password onPressEnter={e => login()} className='mb-2x rounded' type='text' prefix={<LockOutlined className="site-form-item-icon" />} placeholder='Serial Number' value={username} onChange={e => setUsername(e.target.value)} />
                            <Input.Password onPressEnter={e => login()} className='rounded' prefix={<LockOutlined className="site-form-item-icon" />} placeholder='Pin' value={password} onChange={e => setPassword(e.target.value)} />
                            <Space wrap>
                                <Button loading={btnLoading} style={{background:'#c60a1c'}} className='btn-primaryx border-0 text-white' onClick={e => login()}><i className='fas fa-unlock me-2' />Login</Button>
                                {/* <Button className='btn-primary border-0' onClick={e => navigate('../../init_psd_recovery')}><i className='fas fa-unlock me-2' />Forgot Password</Button> */}
                            </Space>
                        </Space>
                    </div>
                </div>
            </div>
        </>
    );
}

export default memo(Login);