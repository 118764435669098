import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { createTrackedSelector } from 'react-tracked';
import Settings from '../dependencies/custom/settings';
const store = (set, get) => ({
    getStates: () => {//returns an array 
        const states = get();//zustand get callback function
        return states;
    },
    tables_metadata: {
        method: 'post',
        table: 'tables_metadata',
        url: `${Settings.backend}/bootstrap`,
        storeName: 'tables_metadata',
    },

    dependent_types: {
        method: 'post',
        table: 'dependent_types',
        url: `${Settings.backend}/bootstrap`,
        storeName: 'dependent_types',
    },

    vetting_items: {
        method: 'post',
        table: 'vetting_items',
        url: `${Settings.backend}/bootstrap`,
        storeName: 'vetting_items',
        critfdx: ['is_active'],
        critval: ['1']
    },

    entrymode_vetting_items: {
        method: 'post',
        table: 'entrymode_vetting_items',
        url: `${Settings.backend}/bootstrap`,
        storeName: 'entrymode_vetting_items',
    },

    entry_mode: {
        method: 'post',
        table: 'entry_mode',
        url: `${Settings.backend}/bootstrap`,
        storeName: 'entry_mode',
    },

    settings: {
        method: 'post',
        table: 'settings',
        url: `${Settings.backend}/bootstrap`,
        storeName: 'settings',
        critfdx: ['is_public'],
        critval: ['1'],
        fields: ['*']
    },


    // category: {  
    //     method:'post',      
    //     table: 'category',
    //     url: `${Settings.backend}/bootstrap`,        
    //     storeName: 'categories',
    //     treeDataStoreName:'categoryTreeSelectData',
    //     treeDataStoreNameNoDisabled:'categoryTreeSelectDataNoDisabled'
    // },

    // reg_dist: {  
    //     method:'post',      
    //     table: 'reg_dist',        
    //     url: `${Settings.backend}/bootstrap`,        
    //     storeName: 'reg_dist',
    //     treeDataStoreName:'regDistTreeSelectData',
    //     treeDataStoreNameNoDisabled:'regDistTreeSelectDataNoDisabled'
    // },

    // filters: {  
    //     method:'post',      
    //     table: 'filters',        
    //     url: `${Settings.backend}/bootstrap`,      
    //     storeName: 'filters',        
    // },

    // filter_opt: {  
    //     method:'post',      
    //     table: 'filter_opt',        
    //     url: `${Settings.backend}/bootstrap`,       
    //     storeName: 'filter_opt',        
    // },

    // filter_to_category:{
    //     method:'post',      
    //     table: 'filter_to_category',        
    //     url: `${Settings.backend}/bootstrap`,      
    //     storeName: 'filter_to_category', 
    // },

    // package:{
    //     method:'post',      
    //     table: 'package',        
    //     url: `${Settings.backend}/bootstrap`,     
    //     storeName: 'package', 
    // },

    // package_to_category:{
    //     method:'post',      
    //     table: 'package_to_category',        
    //     url: `${Settings.backend}/bootstrap`,      
    //     storeName: 'package_to_category', 
    // },

    // convo_starter:{
    //     method:'post',      
    //     table: 'convo_starter',        
    //     url: `${Settings.backend}/bootstrap`,      
    //     storeName: 'convo_starter', 
    // },
    // user_extra_details_opt:{
    //     method:'post',      
    //     table: 'user_extra_details_opt',        
    //     url: `${Settings.backend}/bootstrap`,      
    //     storeName: 'user_extra_details_opt', 
    // }
});

const useStore = create(devtools(store));

const useTrackedStore = createTrackedSelector(useStore);
export default useTrackedStore;

