import React, { useMemo, memo } from 'react'
import { useState } from 'react';
import { Card, Button, Select, message, Steps, theme, Space } from 'antd'
import { redirect, useNavigate, useLocation } from 'react-router-dom';
import ValuesStore from '../../store/values-store';
import FormGroup from '../../components/form_group';
import utils from '../../dependencies/custom/react-utilities';
import Settings from '../../dependencies/custom/settings';
import useAdd from '../../hooks/add';
import useTable from '../../hooks/table';
import useDelete from '../../hooks/delete';
import { confirmAlert } from 'react-confirm-alert';
import useReport from '../../hooks/custom/report';
import useDrawer from '../../hooks/drawer';

function AspirantForm() {
    const valuesStore = ValuesStore();
    const [data, setData] = useState();
    const [formGroups, setFormGroups] = useState([]);
    const [current, setCurrent] = useState(() => {
        // Retrieve the active tab from local storage if available, otherwise set default value
        return parseInt(localStorage.getItem('activeTab')) || 0;
        // return 0;
    });
    const { token } = theme.useToken();
    const add = useAdd('tables_metadata', 'table_name');
    const { state } = useLocation();
    const del = useDelete();
    const [hooks, setHooks] = useState();
    const [aspirantDetails, setAspirantDetails] = useState();
    const [aspirantGrades, setAspirantGrades] = useState();
    const [currentForm, setCurrentForm] = useState();
    const { filters, filterTypes } = utils.generateTableFilters();
    // const [tableData, setTableData] = useState([]);
    const [dbData, setDBData] = useState();
    const [forceRenderer, setForceRenderer] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(false);
    const drawer = useDrawer();
    const report = useReport({ drawer });

    const navigate = useNavigate();
    const userTable = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        undefined,
        undefined,
        undefined,
        undefined,
        'id',
        {},
        { table: '', fields: [] });



    const columns = ([
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    {/* {console.log(record, state)} */}
                    <Button className='btn-successx border-0x' onClick={e => sendEmail(record)}><i className='fas fa-envelope text-primary' /></Button>
                    <Button className='btn-successx border-0x' onClick={e => editRecord(record)}><i className='fas fa-edit text-success' /></Button>
                    {del.confirm(
                        `${Settings.backend}/delete`,
                        record,
                        'Are you sure to delete this item',
                        { tableName: 'aspirant_details', where: `aspirant_id='${record?.index_no}' AND applicant_id='${state?.extraDetails[0]?.applicant_id}'`, whereType: 'opened' },
                        <Button className='btn-dangerx border-0x'>  <i className='fas fa-trash text-danger' /></Button>, undefined, undefined, undefined, async () => {
                            await utils.requestWithReauth('post', `${Settings.backend}/delete`, { tableName: 'aspirant', where: `index_no='${record?.index_no}' AND parent_id='${state?.extraDetails[0]?.applicant_id}`, whereType: 'opened' })
                        }
                    )}
                </Space>
            },
        },
        {
            title: 'Index No.',
            dataIndex: 'index_no',
        },
        {
            title: 'Surname',
            dataIndex: 'sname',
        },
        {
            title: 'Firstname',
            dataIndex: 'fname',
        },
        {
            title: 'Hall',
            dataIndex: 'hall',
        }
    ]);



    async function sendEmail(record) {
        let email = currentForm == 'PROPOSER' ? record?.proposer_email :
            (currentForm == 'SECONDER' ? record?.seconder_email : record?.vice_email);
        if (!email || !utils.ValidationFactory?.validateSimpleEmail(email)) {
            message.error('Invalid or no Email entered');
            return;
        }
        setFetchLoading(true);
        const res = await utils.requestWithReauth('post', `${Settings.backend}/send_email`, null,
            {
                email,
                fname: record?.fname,
                receiver_index_no: record?.index_no,
                applicant_id: state?.extraDetails[0]?.applicant_id,
                sender_index_no: state?.extraDetails[0]?.index_no,
                currentForm
            });
        if (res?.status == 'Ok') {
            utils.showNotification(undefined, res?.msg, 'text-success');
        } else {
            utils.showNotification(undefined, res?.msg);
        }
        setFetchLoading(false);
    }



    async function editRecord(record) {
        add.setRecord(record);
        add.setSaveCompleted(false);

        const mapperObject = await utils.getSettings(valuesStore, 'mapperObject');
        const object = JSON.parse(mapperObject || "{}");

        // const current = currentForm?.toLowerCase();
        const current = currentForm;
        const tableMapperObj = current == 'PROPOSER' ? object?.ProposerTable : (current == 'SECONDER' ? object?.SeconderTable : object?.ViceTable);
        const result = utils.mapMatchingKeys(record, tableMapperObj);
        add.setRecord(result.allMerged);
    }


    async function getFormDetails() {
        let res = await utils.requestWithReauth('post', `${Settings.backend}/get_form_details`, null,
            {
                form_type: localStorage.getItem('selected_form'),
                user_id: state?.isDefaultLogin ? state?.extraDetails[0]?.applicant_id : state?.extraDetails[0]?.index_no,
                is_default_login: state?.isDefaultLogin,
                dependent_type: state?.dependentType
            });

        // console.log(res)
        if (res?.status == 'Ok') {
            setFormGroups(res?.result);
            setDBData(res?.data);
        }
        return res;
    }

    function setCurrentFormDetails(res, current) {
        if (!res) return;
        //res contains the various form sections. use the various form sections & their corresponding table_id and feed these table_id to the add hook
        const form = res[current]?.table_id;
        add.setTblName(form);
        add.setSaveCompleted(false);
        setCurrentForm(form);
        localStorage.setItem('activeTab', current);
        buildTableHookObjectFromDBRes(dbData, form);
        return form;
    }

    function configUpload() {
        add.upload.setAcceptedFiles(['application/pdf', 'image/png', 'image/jpeg']);
        add.upload.setCustomBeforeUpload({
            async beforeUpload(file, b) {
                if (b.includes(file.type)) {
                    const base64 = await utils.toBase64(file);
                    add.upload.setBase64FileList(files => [...files, { meta: add.upload.metaData, file: base64 }]);
                } else {
                    utils.showNotification(undefined, 'Unsupported file type');
                }
            }
        });
        add.upload.setDelFile({
            async delete(file) {
                await utils.deleteFile(file);
            }
        });
        add.upload.setNumFiles(1);
    }

    function showReport(record = { applicant_id: state?.extraDetails[0]?.applicant_id, index_no: state?.extraDetails[0]?.index_no }) {
        report.setData(record);
        drawer.setOpen(true);
    }

    async function previewForm() {
        await save();
        showReport();
        await utils.sleep(500);
        drawer.setTitle(<div className='d-flex justify-content-between'>
            <label>Aspirant ID:{state?.extraDetails[0]?.applicant_id} / Index Number:{state?.extraDetails[0]?.index_no}</label>
            <Space>
                <Button onClick={e => submitForm()} className='btn-success' icon={<i className='fas fa-save me-1' />}>Submit</Button>
                <Button onClick={e => report.handlePrint()} type='primary' icon={<i className='fas fa-print me-1' />}>Print</Button>
            </Space>
        </div>)
    }

    async function submitForm() {
        drawer.setOpen(false);
        confirmAlert({
            title: 'Confirm form submission',
            message: 'Continuing will lock the form. Click on Yes to continue. Click on On to stop',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => { await (save(true, localStorage.getItem('selected_form'))) }
                },
                {
                    label: 'No',
                    // onClick: async () => { await (save()) }
                }
            ]
        });
    };

    async function save(hasCompleted = false, selectedForm = localStorage.getItem('selected_form')) {
        const v = add.validateShowErrorMessage();
        if (!v?.isValid) {
            return;
        }
        const isSavable = formGroups[current]?.is_savable;
        if (parseInt(isSavable)) {
            const specialSaveEndpoint = formGroups[current]?.special_save_endpoint;
            const table = formGroups[current]?.table_id;
            const data = valuesStore.getValuesBy('tables_metadata', 'table_name', table);
            let values = {};
            for (let field of data) {
                const colName = field?.column_name;
                values[colName] = add?.record[colName];
            }
            const url = specialSaveEndpoint ? specialSaveEndpoint : `${Settings.backend}/save_form_details`;
            const file = add.upload.base64FileList;
            const td = userTable.data;
            setSaveLoading(true);
            let res = await utils.requestWithReauth('post', url, null, { tableData: td, table, values, details: state.extraDetails[0], files: file, hasCompleted, selectedForm });
            // console.log(res);
            if (res.status == 'Ok') {
                setForceRenderer(!forceRenderer);
                add.reset();
                if (hasCompleted) {
                    utils.showNotification(undefined, 'Your details have been received. Thanks', 'text-success');
                    utils.logout(navigate, 'token', '../../login');
                }
            } else {
                utils.showNotification(res?.msg, 'Error saving details. Duplicate index numbers not allowed')
            }
            setSaveLoading(false);
            return res;
        }
        return {};
    }


    async function buildTableHookObjectFromDBRes(data, currentForm) {
        const groupedLookups = utils.groupBy(data, 'lookup_id');

        let groupedLookupsObject = {};
        for (let key in groupedLookups) {
            const items = groupedLookups[key];
            const groupedIndexNo = utils.groupBy(items, 'index_no');
            groupedLookupsObject[key] = groupedIndexNo;
        }

        const students = groupedLookupsObject[currentForm];

        userTable.setLoading(true);
        const mapperObject = await utils.getSettings(valuesStore, 'mapperObject');
        const object = JSON.parse(mapperObject || "{}");
        // const current = currentForm?.toLowerCase();
        const current = currentForm;
        const tableMapperObj = current == 'PROPOSER' ? object?.ProposerTable : (current == 'SECONDER' ? object?.SeconderTable : object?.ViceTable);
        let tempArr = [];
        let counter = 0;
        for (let key in students) {
            let student = students[key];
            const res = utils.getObjectFromArrayofObjects(student, 'item', 'value');
            const td = utils.objectKeysMapper(res, tableMapperObj);//convert object keys to suit table columns
            tempArr.push({ id: td?.index_no || `${key}_${counter}`, ...td });
            counter++;
        }
        userTable.setData(tempArr);
        userTable.setLoading(false);
    }

    function buildAddHookObjectFromDBRes(data, currentForm) {

        let object = {};
        for (let item of data || []) {
            const lookupID = item?.lookup_id;
            if (lookupID == currentForm) {
                const datatype = item?.['datatype'];
                const key = item?.['item'];
                const value = item?.['value'];
                if (datatype != 'file') {//skip files. don't set file paths in the add hook. it causes errors in db operations. note that the db error has been fix but we still need this for foolproof
                    object[key] = value;
                }
            }
        }

        add.upload.setFileList([]);
        //for file handling
        for (let item of data || []) {
            const lookupID = item?.lookup_id;
            if (lookupID == currentForm) {
                const datatype = item?.['datatype'];
                const extraParams = JSON.parse(item?.['extra_params'] || "{}");
                const value = item?.['value'];
                if (datatype == 'file') {
                    const file = { name: extraParams?.fileName || value, sysname: value, key: value, path: value };
                    add.recallSingleFile(file, 'value', 'aspirant_details', `${Settings.backend}/del_file_del_row`, 'id', item?.details_id, 'files')
                }
            }
        }
        return object;
    }

    //the state variable in the useLocation only holds data for the aspirant who has currently logged in 
    //this is function is for getting forms that are to load from the state variable in the useLocation hook
    function loadFromLoginState(formsComponents = []) {
        return formsComponents?.map(v => {
            return v?.is_for_main_aspirant == 1 && v?.table_id;
        })?.filter(v => v);
    }
    // console.log(currentForm)
    async function load() {
        const r = await getFormDetails();
        const currentForm = setCurrentFormDetails(r?.result, current);
        const data = buildAddHookObjectFromDBRes(r?.data, currentForm);

        buildTableHookObjectFromDBRes(r?.data, currentForm);
        const details = state?.details?.student[0];
        const grades = state?.details?.studentGrades;

        setAspirantDetails(details);
        setAspirantGrades(grades);
        await utils.sleep(500);
        const mapperObject = await utils.getSettings(valuesStore, 'mapperObject');
        const res = utils.objectKeysMapper(details, JSON.parse(mapperObject || "{}")?.[currentForm]);
        const itemsToLoadFromLoginState = loadFromLoginState(r?.result);
        if (itemsToLoadFromLoginState.includes(currentForm)) {//THESE FORMS ARE FOR THE MAIN ASPIRANT
            console.log({ ...res, ...data });
            add.setRecord({ ...res, ...data });//the data and res have some keys that are the same and we want the data values 
            //to override or append values in the res for the add hook forms
        } else {
            add.setRecord({ ...data });
        }

        const allowEditing = await utils.getSettings(valuesStore, 'allowEditing');
        if (parseInt(allowEditing)) {
            valuesStore.updateArrayObjectValue('tables_metadata', 'group_name', 'ASPIRANT', 'disabled', 0);
        }
    }

    async function addStudent(table) {
        const details = add.record;
        const mapperObject = await utils.getSettings(valuesStore, 'mapperObject');
        const object = JSON.parse(mapperObject || "{}");
        const mpobj = object?.[table];


        const tableMapperObj = table == 'PROPOSER' ? object?.ProposerTable : (table == 'SECONDER' ? object?.SeconderTable : object?.ViceTable);
        const mapperRes = utils.getSubObjects(details, Object.values(mpobj));
        const td = utils.objectKeysMapper(mapperRes, tableMapperObj);//convert object keys to suit table columns

        const tableExistingData = userTable.data;
        const exist = tableExistingData?.filter(v => v?.index_no == td?.index_no);
        if (exist.length) {
            message.info(`${td?.index_no} already exist`);
            // return;
        }
        const res = await save();
        // if (res.status == 'Ok') {
        //     navigate(0);
        // }
    }

    async function fetchStudent(table) {
        let index_no = table == 'PROPOSER' ? add.record?.proposer_index_number :
            (table == 'SECONDER' ? add.record?.seconder_index_number : add.record?.vice_index_number);
        if (!index_no) {
            message.error('Invalid or no Index Number entered');
            return;
        }
        setFetchLoading(true);
        const res = await utils.requestWithReauth('post', `${Settings.backend}/get_student`, null, { index_no });
        if (res?.status == 'Ok') {
            const details = res?.details?.student[0];
            const mapperObject = await utils.getSettings(valuesStore, 'mapperObject');
            const object = JSON.parse(mapperObject || "{}");
            const mpobj = object?.[table];
            const mapperRes = utils.objectKeysMapper(details, mpobj);
            add.setRecord(mapperRes);
        }
        setFetchLoading(false);
    }

    useMemo(() => {
        if (!state) {
            utils.logout(navigate, 'token', '../login');
        }
        load();
        userTable.setColumns(columns);
        console.log('looping');
        configUpload();
        userTable.setCssClasses('w-100');
        // console.log(state);
    }, [JSON.stringify(valuesStore.getValue('tables_metadata')), add.upload.uploaderID, forceRenderer, currentForm, del.saveCompleted]);


    const next = async () => {
        const v = add.validateShowErrorMessage();
        if (!v?.isValid) {
            return;
        }
        await save();
        setCurrent(current => {
            setCurrentFormDetails(formGroups, current + 1);
            return current + 1;
        });
    };
    const prev = () => {
        setCurrent(current => {
            setCurrentFormDetails(formGroups, current - 1);
            return current - 1;
        });
    };

    const items = formGroups?.map((item) => ({
        key: item.table_id,
        title: item.group_name,
    }));

    const contentStyle = {
        color: token.colorTextTertiary,
        // backgroundColor: token.colorFillAlter,
        backgroundColor: '#f7f7f7',
        borderRadius: token.borderRadiusLG,
        border: `2px dashed ${token.colorBorder}`,
        marginTop: 16,
    };

    return (
        <div className='fw-bold'>
            <div className='border-0'>
                <div className='mb-2'>
                    <Steps current={current} items={items} />
                </div>
                <div className='p-2x' >
                    <div className='row'>
                        <div className='col-md-4'>
                            <div style={contentStyle} className='p-3'>
                                {add.form}
                            </div>
                        </div>
                        {
                            // We only want this table to show when current form is one of the following
                            ['SECONDER', 'PROPOSER', 'VICE_BIO'].includes(currentForm) && <div className='col-md-8'>
                                <div style={contentStyle} className='p-3'>
                                    {
                                        ['SECONDER', 'PROPOSER', 'VICE_BIO'].includes(currentForm)
                                        && (
                                            parseInt(valuesStore.getArrayObjectsValue('settings', 'prop', 'makeOutboundApiRequest')?.value)
                                                ? (
                                                    <>
                                                        <Button loading={fetchLoading} className='btn-warning border-0 mb-2 me-2' onClick={() => fetchStudent(currentForm)} icon={<i className='fas fa-paper-plane me-1' />}>
                                                            Fetch
                                                        </Button>
                                                        <Button loading={saveLoading} className='btn-primary border-0 mb-2' onClick={() => addStudent(currentForm)} icon={<i className='fas fa-save me-1' />}>
                                                            Save
                                                        </Button>
                                                    </>) :
                                                <Button loading={saveLoading} className='btn-warning border-0 mb-2' onClick={() => addStudent(currentForm)} icon={<i className='fas fa-paper-plane me-1' />}>
                                                    Save
                                                </Button>
                                        )
                                    }
                                    <div className='table-responsive h-scrolling-wrapper'>
                                        {userTable.table}
                                    </div>

                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div className='mt-2'>
                    {current < formGroups.length - 1 && (
                        <Button type="primary" loading={saveLoading} icon={<i className='fas fa-save me-1' />} onClick={() => next()}>
                            Save & Continue
                        </Button>
                    )}
                    {current === formGroups.length - 1 && (
                        <Button type="primary" onClick={() => previewForm()}>
                            Preview Form
                        </Button>
                    )}
                    {current > 0 && (
                        <Button
                            style={{
                                margin: '0 8px',
                            }}
                            onClick={() => prev()}
                        >
                            Previous
                        </Button>
                    )}
                </div>
            </div>
            {add.upload.preview()}
            {/* {report.reportJSX()} */}
            {drawer.drawerJSX(undefined, report.reportJSX())}
        </div>
    )
}

export default memo(AspirantForm);