import React, { useState, useMemo } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme, Image, message, Avatar } from 'antd';
import utils from '../../dependencies/custom/react-utilities';
import Settings from '../../dependencies/custom/settings';
import ValuesStore from '../../store/values-store';
const { Header, Content, Footer, Sider } = Layout;



const AspirantHome = () => {
    const valuesStore = ValuesStore();
    const navigate = useNavigate();
    const qh = utils.getHString();
    const [appDetails, setAppDetails] = useState();
    const [selectedMenuItem, setSelectedMenuItem] = useState('1');
    const [pages, setPages] = useState([]);
    const [tokenValid, setTokenValid] = useState(false);
    // const [breadCrumbItems, setBreadCrumbItems] = useState([]);
    const { state } = useLocation();

    // const bgColor = '#546E7A';
    // const bgColor = '#547A6E';
    const bgColor = '#eef1f9';
    const navBgColor = '#4E6A7A';//b7000f
    // const bgColor ='#7A545E';


    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    async function getAssignedPages() {
        const token = utils.getCookie('token');
        let res = await utils.requestWithReauth('post', `${Settings.backend}/get_assigned_pages`, null, { token });
        valuesStore.setValue('permitted_routes', res);
        if (Array.isArray(res)) {
            const pages = res?.map(r => {
                return {
                    key: r?.id,
                    icon: <i className={`${r?.icon}`} />,
                    label: <label onClick={e => changePage(r?.path, r?.id.toString())}>{r?.description}</label>,
                }
            });
            setPages(pages);
        } else {            
            utils.showNotification(undefined, res.msg);
        }
    }

    async function getAssignedPemissions() {
        const token = utils.getCookie('token');
        let res = await utils.requestWithReauth('post', `${Settings.backend}/get_assigned_permissions`, null, { token });
        valuesStore.setValue('permissions', res);
    }

    function changePage(page, key) {
        setSelectedMenuItem(key);
        navigate(page);
    }

    function menu() {
        return <Menu
            className='h-scrolling-item'
            mode="inline"
            style={{
                height: '100%',
            }}
            items={pages}
        />
    }

    function getBreadCrumb() {
        const inLocationStorage = JSON.parse(localStorage.getItem('selected_form_details') || "{}");
        const inState = state?.formType;
        const res = inState ? { title: inState[0]?.name } : { title: inLocationStorage?.name };
        // setBreadCrumbItems([{ title: 'Aspirant' }, res]);
    }

    useMemo(() => {
        // getBreadCrumb();
        const institution = valuesStore.getArrayObjectsValue('settings', 'prop', 'INSTITUTION_DETAILS')?.value;
        if (institution) {
            setAppDetails(JSON.parse(institution));
        }
        
        if (qh['page']) {
            setSelectedMenuItem(qh['page']);
        }
        getAssignedPages();
        getAssignedPemissions();
        utils.verifyToken(navigate, setTokenValid, undefined, undefined, undefined);

    }, [window.location.hash, valuesStore['settings']]);

    return (
        // <Watermark content={['ELECTORAL COMMISSION', 'AAMUSTED']}>
        <Layout style={{ background: bgColor }}>
            <Header
                style={{
                    position: 'sticky',
                    top: 0,
                    zIndex:1,
                    background: navBgColor
                }}
            >
                <div className='text-white d-flex justify-content-between'>
                    <div>
                        <div className='d-flex'>
                            <center className="demo-logo" >{<Image src={`${Settings.backend}/fav.png`} width={70} preview={false} />}</center>
                            <div className='fw-bold'>AAMUSTED Elections v0.1.1</div>
                        </div>
                    </div>
                    <div>
                        <Avatar onClick={e => utils.logout(navigate, 'token', '../admin_login')} shape="square" size="large" icon={<i className='fas fa-power-off' />} />
                    </div>
                </div>
            </Header>
            <Content
                style={{
                    padding: '0 48px',
                }}
            >
                {/* <Breadcrumb className='m-3 text-whitex fw-bold' items={breadCrumbItems} /> */}

                <Layout
                    className='border mt-5'
                    style={{
                        padding: '24px 0',
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG,
                    }}
                >
                    <Sider
                        // collapsed={true}
                        style={{
                            background: colorBgContainer,
                        }}
                        width={200}
                    >
                        {menu()}
                    </Sider>
                    <Content
                        style={{
                            padding: '0 24px',
                            minHeight: 280,
                        }}
                    >
                        {/*tokenValid &&*/ <Outlet />}
                    </Content>
                </Layout>
            </Content>
            <Footer className='mt-3 text-center text-whitex p-4' style={{ background: bgColor }}>
                AAMUSTED Elections ©{new Date().getFullYear()}
            </Footer>
        </Layout>
        // </Watermark>
    );
};
export default AspirantHome;



