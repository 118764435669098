
import React, { useState, useEffect, memo, useMemo, } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Button, Spin, Result } from 'antd';
import { LoadingOutlined } from '@ant-design/icons'

import Settings from '../../dependencies/custom/settings';


const DependentLogin = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    const [complete, setComplete] = useState(false);
    const [callbackURL, setCallbackURL] = useState(false);
    const [error, setError] = useState();
    useMemo(() => {
        // console.log(location.search);
        const qs = location.search.split('&');
        const token = qs[0]?.replace('?', '')?.split('=')[1];
        const callback = qs[1]?.split('=')[1];
        verifyToken(token);
        setCallbackURL(callback);
    }, []);


    function goHome() {
        window.location.href = callbackURL || 'https://aamusted.edu.gh';
    }


    async function verifyToken(token) {
        const res = await utils.request('post', `${Settings.backend}/dependent_login`, null, { email_token: token });
        if (res?.status == 'Ok') {
            if (res?.allowLogin) {
                const credentials = utils.objectParamLocator(res, ['credentials']);
                const accessToken = res?.access_token?.token || credentials?.access_token?.token;
                const refreshToken = res?.refresh_token?.token || credentials?.refresh_token?.token;
                
                if (accessToken) {
                    utils.setCookie('token', accessToken, 0.5);
                    localStorage.setItem('access_token_expiry_time', (res?.access_token?.exp || credentials?.access_token?.exp) * 1000);
                }
                if (refreshToken) {
                    utils.setCookie('refreshToken', refreshToken, 0.5);
                }

                navigate('../aspirant/form', { state: res })
            } else {
                setComplete(true);
            }
        } else {
            utils.showNotification(undefined, 'It seems there is an issue with the link...');
            setError(<Result
                status="403"
                title="403"
                subTitle="Sorry, you are not authorized to access this page."
                extra={<Button type="primary" onClick={e => goHome()}>Back Home</Button>}
            />);
        }
    }


    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (
        <>
            {
                complete ? <Result
                    status="success"
                    title="Nomination successful!"
                    // subTitle="Click to go home"
                    extra={[
                        <Button type="primary" key="home" onClick={e => goHome()}>
                            Go Home
                        </Button>
                    ]}
                /> :
                    (error ? error : <Spin indicator={antIcon} />)
            }

        </>
    );
}

export default memo(DependentLogin);