
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, Card, theme, DatePicker, Image } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
import useModal from '../../hooks/modal';
import { useReactToPrint } from "react-to-print";


const VoucherMGT = (props) => {

    const valuesStore = ValuesStore();
    const add = useAdd('tables_metadata', 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    // const keyOverrides = { categoryAlias: 'category' };
    const [dateRange, setDateRange] = useState([]);
    const modal = useModal();
    const [generatedVoucher, setGeneratedVoucher] = useState({});
    const [forceRenderer, setForceRenderer] = useState(false);
    const { token } = theme.useToken();
    const printableRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printableRef.current,
    });

    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_data`,
        'post',
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: 'serial_pin', fields: ['*'], orderBy: 'date_inserted DESC' });




    const columns = ([
        {
            title: 'Action',
            key: 'action',
            render: (v, record) => {
                return <Space size="middle">
                    <Button className='btn-successx border-0x' onClick={e => getVoucherToPrint(record)}><i className='fas fa-print text-success' /></Button>
                    <Button className='btn-successx border-0x' onClick={e => editVoucher(record)}><i className='fas fa-edit text-primary' /></Button>
                </Space>
            },
        },
        {
            title: 'Serial No.',
            dataIndex: 'serial_no',
            ...table.getColumnSearchProps('serial_no'),
        },
        {
            title: 'Pin',
            dataIndex: 'pin',
            ...table.getColumnSearchProps('pin'),
        },
        {
            title: 'Entry Mode',
            dataIndex: 'entry_mode',
            filterSearch: true
            // ...table.getColumnSearchProps('entry_mode'),
        },
        {
            title: 'Date',
            dataIndex: 'date_inserted',
            ...table.getColumnSearchProps('date_inserted'),
            render(v, record) {
                return utils.formatDate(v, '-')
            }
        },
        {
            title: 'Expiry',
            dataIndex: 'expires',
            ...table.getColumnSearchProps('expires'),
            render(v, record) {
                return utils.formatDate(v, '-')
            }
        },
        // {
        //     title: 'SalePoint',
        //     dataIndex: 'bank',
        //     ...table.getColumnSearchProps('bank'),
        // },
        {
            title: 'Txn.ID',
            dataIndex: 'txn_id',
            ...table.getColumnSearchProps('txn_id'),
        },
        {
            title: 'Year',
            dataIndex: 'acad_year',
            ...table.getColumnSearchProps('acad_year'),
        },
        {
            title: 'Locked',
            dataIndex: 'locked',
            render(v, record) {
                return v ? 'Yes' : 'No'
            }
        }
    ]);

    const colFilters = [
        {
            filter: 'entry_mode',
            sql: "SELECT name,alias FROM entry_mode",
            key: 'alias',
            value: 'name'
        }
    ]

    function editVoucher(record) {

    }

    async function loadForm() {
        await utils.sleep(2000);
        add.setTblName('serial_pin');
    }

    async function generateVoucher() {
        const v = add.validateShowErrorMessage();
        if (!v?.isValid) {
            return;
        }
        const res = await utils.requestWithReauth('post', `${Settings.backend}/generate_voucher`, null, add.record);
        if (res.status == 'Ok') {
            const details = res?.details;
            setGeneratedVoucher(details);
            setForceRenderer(!forceRenderer);
            modal.setOpen(true);
            add.reset();
        } else {
            utils.showNotification(undefined, res?.msg);
        }
    }

    function getVoucherToPrint(record) {
        setGeneratedVoucher(record);
        modal.setOpen(true);
    }

    function printVoucher() {
        modal.setOpen(true);
    }

    async function printAndUpdateDB() {
        handlePrint();
        if (!generatedVoucher?.has_printed) {
            await utils.requestWithReauth('post', `${Settings.backend}/update_voucher_print_status`, null, generatedVoucher);
        }
    }

    useMemo(() => {

        table.setColumns(columns);
        table.setColFilters(colFilters, columns, `${Settings.backend}/get_col_filters`);
        loadForm();
        console.log('looping')


    }, [add.saveCompleted, del.saveCompleted, table.extraFetchParams, forceRenderer]);



    // function arrayOfObjectsKeyValueToObject(data, key, value) {
    //     let dt = {};
    //     data?.forEach(v => {
    //         dt[v[key]] = v[value];
    //     });
    //     return dt;
    // }

    const contentStyle = {
        color: token.colorTextTertiary,
        backgroundColor: '#ebebeb',
        borderRadius: token.borderRadiusLG,
        border: `2px dashed ${token.colorBorder}`,
    };


    return (
        <>
            {/* {console.log('render render')} */}
            <div className='containerx fw-bold' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>
                    <div className='col-md-12 mb-2'>
                        <Card
                            // style={contentStyle}
                            bordered={false}
                            className="cricleboxx tablespace borderx mb-24"
                            title="Vouchers"
                            extra={
                                <Space>
                                    <DatePicker.RangePicker onChange={v => setDateRange(v)} value={dateRange} />
                                    <Button className='btn-success border-0' onClick={e => utils.filterByDate('date_inserted', dateRange, table)} icon={<i className='fas fa-filter me-1' />}>Filter</Button>
                                </Space>
                            }
                        >
                            <div className='row mt-2 mb-2'>
                                <div className='col-md-3 mb-2'>
                                    <div className='p-2' style={contentStyle}>
                                        <div className=''>
                                            <span className='h6 text-danger'>Serial Number: {generatedVoucher?.serial_no}</span><br />
                                            <span className='h6 text-danger'>Pin: {generatedVoucher?.pin}</span>
                                        </div>
                                        <hr />
                                        {add.form}
                                        <Space>
                                            <Button className='btn-secondary border-0' onClick={e => generateVoucher()} icon={<i className='fas fa-unlock me-1' />}>Generate</Button>
                                            <Button className='btn-success border-0' onClick={e => printVoucher()} icon={<i className='fas fa-print me-1' />}>Print</Button>
                                        </Space>
                                    </div>
                                </div>
                                <div className='col-md-9'>
                                    <div className='p-2' style={contentStyle}>
                                        {table.tableWithHeaderFooter()}
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            {modal.modalJSX(undefined,
                <div className='p-2' /*style={contentStyle}*/ ref={printableRef}>
                    <div className='row'>
                        <div className='col-md-12 borderx'>
                            <div className=''>
                                <center className="demo-logo" >{<Image src={`${Settings.backend}/fav.png`} width={200} preview={false} />}</center>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='text-muted text-center h4'>AAMUSTED NOMINATION FORM VOUCHER</div>
                            <span className='h5'>Serial Number: {generatedVoucher?.serial_no}</span><br />
                            <span className='h5'>Pin: {generatedVoucher?.pin}</span><br />
                            <span className='h5'>Transaction ID: {generatedVoucher?.txn_id}</span><br />
                            <span className='h5'>Buyer: {generatedVoucher?.buyer && `${generatedVoucher?.buyer}/`}{generatedVoucher?.buyer_telephone}</span><br />
                            <span className='h5'>Date:{generatedVoucher?.date_inserted}</span><br />
                            <span>Please visit https://smgtcs.aamusted.edu.gh</span>
                        </div>
                    </div>
                </div>, undefined, {
                footer:
                    <Space>
                        <Button type='primary' onClick={e => printAndUpdateDB()} icon={<i className='fas fa-print me-1' />}>Print</Button>
                        <Button onClick={e => modal.setOpen(false)}>Cancel</Button>
                    </Space>
            }
            )}
        </>
    );
}

export default memo(VoucherMGT);