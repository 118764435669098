

import React, { useState, useMemo } from 'react';
import { Outlet, useNavigate, useLocation, Link } from 'react-router-dom';

import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Card, theme, Image, Avatar } from 'antd';
import utils from '../../dependencies/custom/react-utilities';
import Settings from '../../dependencies/custom/settings';
import ValuesStore from '../../store/values-store';
import useReport from '../../hooks/custom/report';
import useDrawer from '../../hooks/drawer';
import useTable from '../../hooks/table';

const { Header, Content, Footer, Sider } = Layout;



const AspirantHome = () => {
    const valuesStore = ValuesStore();
    const navigate = useNavigate();
    const qh = utils.getHString();
    const [appDetails, setAppDetails] = useState();
    // const [selectedMenuItem, setSelectedMenuItem] = useState('1');
    // const [pages, setPages] = useState([]);
    const [tokenValid, setTokenValid] = useState(false);
    const [relatedForms, setRelatedForms] = useState([]);
    const [nominationScores, setNominationScores] = useState(0);
    const { state } = useLocation();
    const bgColor = '#eef1f0';
    const navBgColor = '#4E6A7A';//b7000f
    const drawer = useDrawer();
    const report = useReport({ drawer });
    const { filters, filterTypes } = utils.generateTableFilters();

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters, },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_data`,
        'post',
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: 'feedback', fields: ['*'] });


    async function getEntryModes() {
        let res = await utils.requestWithReauth('post', `${Settings.backend}/get_related_forms`, null, { form_type: state?.voucherDetails?.elevated_to });
        setRelatedForms(res?.result);
    }

    async function load() {
        await getEntryModes();
    }
    async function setSelectedMode(selected_form) {
        localStorage.setItem('selected_form', selected_form?.alias);
        localStorage.setItem('selected_form_details', JSON.stringify(selected_form));
        let res = await utils.requestWithReauth('post', `${Settings.backend}/set_selected_form`, null, { selected_form: selected_form?.alias, id: state?.voucherDetails?.id });
    }

    function showReport(record) {
        report.setData(record);
        drawer.setOpen(true);
    }
    const columns = ([
        {
            title: 'Feedback',
            dataIndex: 'remark',
            // ...table.getColumnSearchProps('remark'),
            width:'80%',
            render(v, record) {
                return <label className='fw-bold text-muted text-wrap'>{v}</label>
            }
        },
        {
            title: 'Date',
            dataIndex: 'date_inserted',
            ...table.getColumnSearchProps('date_inserted'),
            width:'20%',
            render(v, record) {
                return <label className='fw-bold text-muted text-wrap'>{utils.formatDate(v, '-')}</label> 
            }
        },
    ]);

    async function getNominationScores() {
        if (parseInt(state?.voucherDetails?.locked)) {
            let res = await utils.requestWithReauth('post', `${Settings.backend}/get_nomination_scores`, null, { applicant_id: state?.extraDetails[0]?.applicant_id });
            if (res?.status == 'Ok') {
                setNominationScores(parseFloat(res?.result?.scores)?.toFixed(0));
            } else {
                utils.showNotification(undefined, res?.msg);
            }
        }
    }

    function reportViewer() {
        return <div className='row' style={{ cursor: 'pointer' }}>
            <div className='col-md-3 mb-2'>
                <Card
                    onClick={e => showReport({ applicant_id: state?.extraDetails[0]?.applicant_id, index_no: state?.extraDetails[0]?.index_no })}
                    style={{
                        background: '#f9f9f9',
                        boxShadow: 'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px'
                    }}
                    cover={
                        <div className='pt-5'>
                            <center>
                                <i style={{
                                    color: utils.getRandomcolorV2(),
                                }} className={'fas fa-print fa-3x'} />
                            </center>
                        </div>
                    }
                >
                    <Card.Meta title={<center className='text-whitex'>View Application Summary</center>} />
                </Card>
            </div>
            <div className='col-md-3 mb-2'>
                <Card
                    onClick={e => showReport({ applicant_id: state?.extraDetails[0]?.applicant_id })}
                    style={{
                        background: '#f9f9f9',
                        boxShadow: 'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px'
                    }}
                    cover={
                        <div className='pt-5'>
                            <center className='h3 text-muted'>
                                {isNaN(nominationScores) ? 0 : nominationScores}/{valuesStore?.getArrayObjectsValue('settings', 'prop', 'vettingScoreSheetTotal')?.value}
                            </center>
                        </div>
                    }
                >
                    <Card.Meta title={<center className='text-whitex'>Nomination Scores</center>} />
                </Card>
            </div>
            <div className='col-md-6 mb-2'>
                <div className='rounded' style={{
                    background: '#f9f9f9',
                    boxShadow: 'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px'
                }}>
                    {table.table}
                </div>
            </div>
        </div>
    }

    function generateCards() {
        return <div className='row'>
            {
                parseInt(state?.voucherDetails?.locked) ? reportViewer() :
                    <>
                        <div className='mb-3'>
                            <label className='h3 text-muted'>Hi Aspirant, please choose your desired form...</label>
                        </div>
                        {relatedForms?.map((v, i) => {
                            return (
                                <div key={`${v?.alias}_${i}`} className='col-md-3 mb-2' onClick={e => setSelectedMode(v)}>
                                    <Link to={'../aspirant/form'} state={state}>
                                        <Card

                                            style={{
                                                background: '#f9f9f9',
                                                boxShadow: 'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px'
                                            }}
                                            cover={
                                                <Avatar
                                                    style={{
                                                        backgroundColor: utils.getRandomcolorV2(),
                                                    }}
                                                    className='mx-auto mt-3'
                                                    size={{
                                                        xs: 24,
                                                        sm: 32,
                                                        md: 40,
                                                        lg: 64,
                                                        xl: 80,
                                                        xxl: 100,
                                                    }}
                                                    icon={<i className={v?.icon} />}
                                                />
                                            }
                                        >
                                            <Card.Meta title={<center className='text-whitex'>{v.name}</center>} description={<center className='text-whitex'>{v.description}</center>} />
                                        </Card>
                                    </Link>
                                </div>
                            )
                        })}
                    </>
            }
        </div>
    }

    useMemo(() => {
        const institution = valuesStore.getArrayObjectsValue('settings', 'prop', 'INSTITUTION_DETAILS')?.value;
        if (institution) {
            setAppDetails(JSON.parse(institution));
        }
        table.setColumns(columns);
        table.setExtraFetchParams({ customFilter: `aspirant = '${state?.extraDetails[0]?.applicant_id}'` });

        // console.log(state);
        // getAssignedPemissions();
        utils.verifyToken(navigate, setTokenValid, undefined, undefined);

        load();
        getNominationScores();
    }, [window.location.hash, valuesStore['settings']]);

    return (
        <Layout style={{ background: bgColor }}>
            <Header
                style={{
                    background: navBgColor
                }}
            >
                <div className='text-white d-flex justify-content-between'>
                    <div>
                        <div className='d-flex'>
                            <center className="demo-logo" >{<Image src={`${Settings.backend}/fav.png`} width={70} preview={false} />}</center>
                            <div className='fw-bold'>AAMUSTED Elections v0.1.1</div>
                        </div>
                    </div>
                    <div>
                        <Avatar onClick={e => utils.logout(navigate, 'token', '../login')} shape="square" size="large" icon={<i className='fas fa-power-off' />} />
                    </div>
                </div>
            </Header>
            <Content
                style={{
                    padding: '0 48px',
                }}
            >
                <div className='mt-5'>
                    <Layout
                        // className='border'
                        style={{
                            padding: '24px 0',
                            // background: colorBgContainer,
                            background: bgColor,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                        <Content
                            style={{
                                padding: '0 24px',
                                minHeight: 280,
                            }}
                        >
                            {generateCards()}
                        </Content>
                    </Layout>
                </div>
            </Content>
            <Footer className='mt-3 text-center text-whitex p-4' style={{ background: bgColor }}>
                AAMUSTED Elections ©{new Date().getFullYear()}
            </Footer>
            {drawer.drawerJSX(undefined, report.reportJSX())}
        </Layout>
        // </Watermark>
    );
};
export default AspirantHome;


