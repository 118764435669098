import React, { useEffect, useMemo, useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import './css/mdb.min.css';
import './font/fontawesome/css/all.min.css'
import './css/custom/animations.css';
import './css/custom/tweeks.css';
// import './css/bootstrap5.min.css';
import "./css/styles/main.css";
import "./css/styles/responsive.css";

import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import usePhoneBaseMenu from './js/components/phoneBaseMenu';
import useWindowDimensions from './js/components/screensize';

import SettingsStore from './js/store/settings-store';
import ValuesStore from './js/store/values-store';
import utils from './js/dependencies/custom/react-utilities'
// import IndexedDB from './js/dependencies/custom/indexeddb'
// import Settings from './js/dependencies/custom/settings';

//admin paths
import AdminHome from './js/pages/admin/home';
import AdminSetting from './js/pages/admin/settings';
import AdminFiles from './js/pages/admin/admin_files';
import AdminPerms from './js/pages/admin/admin_perms';
import AdminRoles from './js/pages/admin/admin_roles';
import Admin from './js/pages/admin/admin';
import AdminRoleFilesLink from './js/pages/admin/admin_role_files_link';
import AdminRolePerm from './js/pages/admin/admin_role_perm';
import AdminRoleLink from './js/pages/admin/admin_role_link';
import Page404 from './js/pages/admin/404';
import Landing from './js/pages/admin/landing';

//aspirant paths
import AspirantHome from './js/pages/aspirant/home';
import AspirantForm from './js/pages/aspirant/form';
import AspirantNotifications from './js/pages/aspirant/form';
import AspirantScores from './js/pages/aspirant/form';
import AspirantLogin from './js/pages/aspirant/login';
import AspirantLanding from './js/pages/aspirant/landing';
//other paths
import Login from './js/pages/admin/login';
import ChangePassword from './js/pages/admin/change_password';
import DependentLogin from './js/pages/aspirant/dependent_login';
import Aspirants from './js/pages/admin/admin_aspirants';
import VoucherMGT from './js/pages/admin/voucher_mgt';
// import InitRecoverPassword from './js/pages/admin/init_recover_password';


function App() {
  const settingsStore = SettingsStore();
  const valuesStore = ValuesStore();
  const navigate = useNavigate();
  const location = useLocation();

  const newLocation = useLocation();
  const urlFileName = utils.getUrlFileName();
  function hasRoute(exempted = []) {    
    const timer = setInterval(() => {
      const routesAvailable = valuesStore.getValue('permitted_routes');
      if (routesAvailable.length > 0) {
        clearInterval(timer);
        const routes = valuesStore.getArrayObjectsValue('permitted_routes', 'path', urlFileName);
        if (Object.keys(routes).length <= 0) {
          if (urlFileName && !exempted.includes(urlFileName?.toLowerCase())) {
            utils.logout(navigate, 'token', '../admin_login');
            // navigate(-1);//go back to the previous page if the requested routes is not found in what the user can access
          }
        }
      }
    }, 1000);
  }

  useMemo(() => {

    if (!(['/login', '/admin_login', '/confirmatory'].includes(location.pathname))) {
      utils.bootstrap(valuesStore, settingsStore);
    }

    hasRoute(['admin_login','landing','form','login','confirmatory','']);
    return () => {
      //clean up here
    };
  }, [newLocation]);

  return (
    <>
      <Routes>
        <Route path='/' element={<AspirantLogin />} />
        <Route path='/admin_login' element={<Login />} />
        <Route path='login' element={<AspirantLogin />} />
        <Route path='landing' element={<AspirantLanding />} />
        <Route path='/confirmatory' element={<DependentLogin />} />

        <Route path='/admin' element={<AdminHome />}>
          <Route path='settings' element={<AdminSetting />} />
          <Route path='admin' element={<Admin />} />
          <Route path='voucher' element={<VoucherMGT />} />
          <Route path='admin_files' element={<AdminFiles />} />
          <Route path='admin_roles' element={<AdminRoles />} />
          <Route path='admin_perms' element={<AdminPerms />} />
          <Route path='admin_role_files_link' element={<AdminRoleFilesLink />} />
          <Route path='admin_role_link' element={<AdminRoleLink />} />
          <Route path='admin_role_perm' element={<AdminRolePerm />} />
          <Route path='change_password' element={<ChangePassword />} />
          <Route path='aspirants' element={<Aspirants />} />
          <Route index path='landing' element={<Landing />} />
          <Route path='*' element={<Page404 homepage='/admin/landing' />} />
        </Route>

        <Route path='/aspirant' element={<AspirantHome />}>
          <Route path='form' element={<AspirantForm />} />
        </Route>

        <Route path='*' element={<Page404 homepage='/admin/landing' />} />

      </Routes>
    </>

  )
}

//disable auto zoom in on iphone and ipad. iphone and ipad automatically zoom in when text size is less than 16px
if (utils.checkIsIOS()) {
  utils.addMaximumScaleToMetaViewport();
}

export default App;
