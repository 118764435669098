
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, Card, theme, Image, Form, Input, Tag, Tooltip } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
import useDrawer from '../../hooks/drawer';
import useDynamicForm from '../../hooks/dynamic_form';
import useReport from '../../hooks/custom/report';
import CustomUtilities from '../../dependencies/custom/custom-functions';
import useModal from '../../hooks/modal';

const Aspirants = (props) => {
    const valuesStore = ValuesStore();
    const add = useAdd('tables_metadata', 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();

    const drawer = useDrawer();
    const modal = useModal();
    const { token } = theme.useToken();
    const [selectedRecord, setSelectedRecord] = useState({});
    const [feedback, setFeedback] = useState('');
    const [selectedApplicant, setSelectedApplicant] = useState({});
    // const [forceRenderer, setForceRenderer] = useState(false);
    const dynamicFormName = 'scores';
    const dynamicForm = useDynamicForm(dynamicFormName, null, { text: 'Submit', type: 'primary' }, onScoresSubmit, false);
    const [selectedFeedback, setSelectedFeedback] = useState({});
    const report = useReport({ drawer });
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_aspirants_for_preview`,
        'post',
        'result',
        'totalCount',
        'id',
        { final_mode: 'IN' },
        { table: '', fields: ['*'] });

    const feedbackTable = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        undefined,
        undefined,
        undefined,
        undefined,
        'id',
        { final_mode: 'IN' },
        {});


    const columns = ([
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Tooltip title='Vetting Scores'>
                        <Button className='btn-secondaryx border-0x' onClick={e => scoreForm(record)}><i className='fas fa-star text-secondary' /></Button>
                    </Tooltip>
                    <Tooltip title='Send Feedback'>
                        <Button className='btn-primaryx border-0x' onClick={e => showFeedbackForm(record)}><i className='fas fa-bell text-primary' /></Button>
                    </Tooltip>
                    <Tooltip title='Aspirant Form Details'>
                        <Button className='btn-successx border-0x' onClick={e => showReport(record)}><i className='fas fa-cog text-success' /></Button>
                    </Tooltip >
                </Space>
            },
        },
        {
            title: 'ApplicantID',
            dataIndex: 'applicant_id',
            ...table.getColumnSearchProps('applicant_id'),
        },
        {
            title: 'Index Number',
            dataIndex: 'index_no',
            ...table.getColumnSearchProps('index_no'),
        },
        {
            title: 'First Name',
            dataIndex: 'firstname',
            ...table.getColumnSearchProps('firstname'),
        },
        {
            title: 'Surname',
            dataIndex: 'surname',
            ...table.getColumnSearchProps('surname'),
        },
        {
            title: 'Form',
            dataIndex: 'final_mode',
            filterSearch: true
        }
    ]);


    const colFilters = [
        {
            filter: 'final_mode',
            sql: 'SELECT name,alias FROM entry_mode',
            key: 'alias',
            value: 'name'
        }
    ];

    const feedbackColumns = ([
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Tooltip title='Edit'>
                        <Button className='btn-secondaryx border-0x' onClick={e => editFeedback(record, 'feeback')}><i className='fas fa-edit text-success' /></Button>
                    </Tooltip>
                    <Tooltip title='Delete'>
                        {del.confirm(
                            `${Settings.backend}/delete`,
                            record,
                            'Are you sure to delete this item',
                            { tableName: 'feedback', where: 'id', whereType: 'closed' },
                            <Button className='btn-dangerx border-0x'>  <i className='fas fa-trash text-danger' /></Button>,
                            undefined, undefined, undefined, () => { modal.setOpen(false) }
                        )}
                    </Tooltip>

                </Space>
            },
        },
        {
            title: 'Author',
            dataIndex: 'author',
            ...table.getColumnSearchProps('applicant_id'),
        },
        {
            title: 'Aspirant',
            dataIndex: 'aspirant',
            ...table.getColumnSearchProps('aspirant'),
        },
        {
            title: 'Remarks',
            dataIndex: 'remark',
            ...table.getColumnSearchProps('remarks'),
        },
        {
            title: 'Date',
            dataIndex: 'date_inserted',
            render(v, record) {
                return utils.formatDate(v, '-');
            }
        }
    ]);

    function editFeedback(record) {
        setSelectedFeedback(record);
        setFeedback(record?.remark);
    }

    async function showFeedbackForm(record) {
        const res = await utils.requestWithReauth('post', `${Settings.backend}/get_feedbacks`, null, { applicant_id: record?.applicant_id });
        if (res.status == 'Ok') {
            feedbackTable.setData(res?.feedbacks);
            modal.setOpen(true);
            setSelectedApplicant(record);
        } else {
            utils.showNotification(undefined, res?.msg);
        }


    }

    async function sendFeedback() {
        const res = await utils.requestWithReauth('post', `${Settings.backend}/send_feedback`, null, { applicant_id: selectedApplicant?.applicant_id, feedback: feedback, id: selectedFeedback?.id });
        if (res.status == 'Ok') {
            utils.showNotification(undefined, 'Feedback sent successfully', 'text-success');
            // setForceRenderer(!forceRenderer);
            modal.setOpen(false);
            setSelectedApplicant({});
            setFeedback('');
            setSelectedFeedback({});
        } else {
            utils.showNotification(undefined, res?.msg);
        }
    }

    function showReport(record) {
        report.setData(record);
        drawer.setOpen(true);
    }

    useMemo(() => {
        table.setColumns(columns);
        feedbackTable.setColumns(feedbackColumns);
        console.log('looping');
        table.setColFilters(colFilters, columns, `${Settings.backend}/get_col_filters`);
    }, [add.saveCompleted, del.saveCompleted, del.saveCompleted]);

    async function onScoresSubmit(values) {
        delete values[dynamicFormName];
        const res = await utils.requestWithReauth('post', `${Settings.backend}/save_aspirant_scores`, null, { values, applicant_id: selectedRecord?.applicant_id });
        if (res.status == 'Ok') {
            utils.showNotification(undefined, 'Operation successful', 'text-success');
            dynamicForm.setShowModal(false);
        } else {
            utils.showNotification(undefined, res?.msg);
        }
    }

    function entryModeGroup(inputEntryMode) {
        const entry_mode = valuesStore.getValue('entry_mode');
        for (let mode of entry_mode) {
            if (inputEntryMode == mode?.alias) {
                return mode?.group_name;
            }
        }
    }

    async function scoreForm(record) {
        setSelectedRecord(record);
        const entryModeGrp = entryModeGroup(record?.final_mode);
        const vettingItems = valuesStore.getValuesBy('entrymode_vetting_items', 'entrymode_group', entryModeGrp);
        if (!vettingItems?.length){
            utils.showNotification(undefined,'Vetting score items not mapped to form');
            return;
        }

        const items = valuesStore.getValuesBy('vetting_items', 'group_name', vettingItems[0]?.vetting_items_group);

        dynamicForm.setModalTitle('Vetting Scores');
        dynamicForm.setFormChildren(
            <div className='d-flexx flex-columnx w-100'>
                {
                    items.map(v => {
                        return <Form.Item
                            key={v?.alias}
                            name={v?.alias}
                            label={v?.item}
                            rules={[
                                {
                                    require: true,
                                    async validator(object, value) {
                                        if (!value || parseFloat(value || 0) > parseFloat(v?.max_score) || parseFloat(value || 0) < 0) {
                                            return Promise.reject(new Error('Mark is required and cannot go above or below the allocated marks'))
                                        }
                                    }
                                }
                            ]}
                        >
                            <Input type='number' />
                        </Form.Item>
                    })
                }
            </div>
        );
        const res = await utils.requestWithReauth('post', `${Settings.backend}/get_aspirant_scores`, null, { applicant_id: record?.applicant_id });
        if (res?.status == 'Ok') {
            const scores = res?.scores;
            const object = arrayOfObjectsKeyValueToObject(scores, 'vetting_item_id', 'marks');
            dynamicForm?.form?.setFieldsValue(object);
        }
        dynamicForm.setShowModal(true);
    }

    function arrayOfObjectsKeyValueToObject(data, key, value) {
        let dt = {};
        data?.forEach(v => {
            dt[v[key]] = v[value];
        });
        return dt;
    }


    return (
        <>
            {/* {console.log('render render')} */}
            <div className='containerx fw-bold' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>
                    <div className='col-md-12 mb-2'>
                        <Card
                            // style={{
                            //     background: '#f9f9f9',
                            //     boxShadow: 'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px'
                            // }}
                            style={CustomUtilities.myStyle(token).contentStyle}
                            bordered={false}
                            className="cricleboxx tablespace borderx mb-24"
                            title="Aspirants"
                            extra={
                                <div className='d-flex'>

                                </div>
                            }
                        >
                            {table.table}
                        </Card>
                    </div>
                </div>
            </div>
            {drawer.drawerJSX(undefined, report.reportJSX())}
            {dynamicForm.formModal(null, null)}
            {modal.modalJSX(sendFeedback, <div className='mt-3'>
                {/* ghostwhite gainsboro */}
                <div className='row'>
                    <div className='col-md-12'>
                        <Input.TextArea className='border-0x' style={{ background: 'ghostwhite' }} onChange={e => setFeedback(e.target.value)} value={feedback} rows={9} />
                    </div>
                    <div className='col-md-12 mt-2'>
                        {feedbackTable.table}
                    </div>
                </div>
            </div>, 700)}
        </>
    );
}

export default memo(Aspirants);