
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, Card, theme } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useAdd from '../../hooks/add';
import useEdit from '../../hooks/edit';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
import CustomUtilities from '../../dependencies/custom/custom-functions';


const Admin = (props) => {
    const { token } = theme.useToken();
    const valuesStore = ValuesStore();
    const add = useAdd('tables_metadata', 'table_name');
    const edit = useEdit('tables_metadata', 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const keyOverrides = { categoryAlias: 'category' };
    const [modalTitle, setModalTitle] = useState('Add New User');
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_data`,
        'post',
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: 'admin', fields: ['*'] });


    const columns = ([
        {
            title: 'Name',
            dataIndex: 'name',
            ...table.getColumnSearchProps('name'),
        },
        {
            title: 'Username/Staff Id',
            dataIndex: 'staff_id',
            ...table.getColumnSearchProps('staff_id'),
        },
        {
            title: 'Primary Role',
            dataIndex: 'primary_role',
            ...table.getColumnSearchProps('primary_role'),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Button className='btn-successx border-0x' onClick={e => editRecord(record, 'admin')}><i className='fas fa-edit text-success' /></Button>
                    {del.confirm(
                        `${Settings.backend}/delete`,
                        record,
                        'Are you sure to delete this item',
                        { tableName: 'admin', where: 'id', whereType: 'closed' },
                        <Button className='btn-dangerx border-0x'>  <i className='fas fa-trash text-danger' /></Button>
                    )}
                </Space>
            },
        },
    ]);





    function editRecord(record, tableName) {
        utils.renameKeys(record, keyOverrides);
        const storeKey = 'editableRecord';
        valuesStore.setValue(storeKey, record);
        edit.setTblName(tableName);
        edit.setData(record);
        edit.setRecordKey(storeKey);
        edit.setShowModal(true);
        edit.setSaveCompleted(false);
    }


    function addRecord(tableName = 'admin') {
        add.setTblName(tableName);
        add.setShowModal(true);
        add.setSaveCompleted(false);
    }

    async function editOnOk() {
        // const v = edit.validateShowErrorMessage();
        // if (!v?.isValid) {
        //     return;
        // }
        // console.log(v)
        let res = await edit.save(
            undefined,
            `${Settings.backend}/edit`, 'admin',
            { tbl: 'admin', where: 'id', whereType: 'closed' },
            undefined);
    }

    async function addOnOk() {
        try {
            const v = add.validateShowErrorMessage();
            if (!v?.isValid) {
                return;
            }
            add.setLoading(true);
            let res = await utils.requestWithReauth('post', `${Settings.backend}/create_user_account`, null, add.record)
            if (res.status == 'Ok') {
                utils.showNotification(undefined, res.msg, 'text-success');
                add.reset();
            } else {
                utils.showNotification(undefined, res.msg);
            }
            add.setLoading(false);
        } catch (err) {
            add.setLoading(false);
        }
    }


    useMemo(() => {
        table.setColumns(columns);
        console.log('looping')
        // table.fetchData();
        table.setExtraFetchParams({ customFilter: `staff_id !='aspirant'` });
    }, [add.saveCompleted, del.saveCompleted,edit.saveCompleted /*table.extraFetchParams*/]);


    return (
        <>
            {/* {console.log('render render')} */}
            <div className='container-fluidx' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>
                    <div className='col-md-12 mb-2'>
                        <Card
                            bordered={false}
                            className="cricleboxx tablespace borderx mb-24 p-2"
                            style={CustomUtilities.myStyle(token).contentStyle}
                            title="Users"
                            extra={
                                <Space>
                                    <Button className='btn-primary border-0' onClick={e => addRecord()}><i className='fas fa-user-plus me-2' /> Add New Item</Button>
                                </Space>
                            }
                        >
                            {table.table}
                        </Card>
                    </div>
                </div>
            </div>
            {add.addModal(modalTitle, addOnOk)}
            {edit.editModal('Edit User', editOnOk)}
        </>
    );
}

export default memo(Admin);